import request from '@/router/axios';
import {businessUrl} from "@/config/env"

export const getTree = () => request({
    url: businessUrl + "",
    method: "post",
    data: {
        baseSpecSheetId: ""
    }
})
export const selectList = (data) => request({
    url: businessUrl + "machineUsers/selectMachineUserPost",//url: businessUrl + "machine/selectByUserChange",
    method: "post",
    data: {
        ...data
    }
})
export const selectWorkShopName = () => request({
    url: businessUrl + "department/selectWorkShopName?type=1",
    method: "get",
    parmas: {
        type: 0
    }
})
export const processSelectByWorkShopId = (departmentId) => request({
    url: businessUrl + "process/selectByWorkShopId?departmentId=" + departmentId,
    method: "get",
    parmas:{
        departmentId
    }
})
export const add = (row,parentId) => request({
    url: businessUrl + "productCategory/insert",
    method: "post",
    data: {
        ...row,
        parentId
    }
})
export const remove = (id) => {
    // alert(id)
    return request({
        url: businessUrl + "productCategory/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const edit = (row) => request({
    url: businessUrl + "productCategory/update",
    method: "put",
    data: {
        ...row
    }
})
//根据岗位/机器编码获取人员
export const getpepoelChange = (cod,postId,time,workShopId) => request({
    url: businessUrl + "machine/selectNameByUserChange",
    method: "post",
    data: {
        machineCode:cod,postId,time,workShopId
    }
})
//根据班次获取班组和员工
export const getClassAndPepole = (suShiftId,code,postId,time,suGroupId,workShopId) => request({
    url: businessUrl + "machine/selectNameByUserChange",
    method: "post",
    data: {
        suShiftId,machineCode:code,postId,time,suGroupId,workShopId
    }
})
//批量替岗
export const batchForDuty = (params) => request({
    url: businessUrl + "machine/updateMachineUser",
    method: "post",
    data: {
        ...params
    }
})
//批量替岗（改版）
export const jobReplaceDetails = (params) => request({
    url: businessUrl + "schedule/editJobReplaceDetails",
    method: "post",
    data: {
        ...params
    }
})
//批量替岗保存前的校验
export const batchForCheck = (params) => request({
    url: businessUrl + "machine/showUpdateMachineUser",
    method: "post",
    data: {
        ...params
    }
})

//查询人员替岗多台机器信息
export const selectAllByUserChange = (params) => request({
    url: businessUrl + "/machine/selectAllByUserChange",
    method: "post",
    data: {
        ...params
    }
})
//下拉人员
export const getUser = () => request({
    url: businessUrl + "corpUsers/selectUserByCorpId",
    method: "post"
})
//模糊查询员工信息
export const getNameByLike = (nameLike) => {
    let formData = new FormData();
    formData.append("nameLike",nameLike);
    return request({
        url: businessUrl + "/corpUsers/selectUserByNameLike",
        method: "post",
        data: formData
    })
}
//查询产量
export const timeYield = (time, machineCode, suShiftId, postId, corpUsersId, suGroupId, changeStartDate, changeEndDate) => request({
    url: businessUrl + "/calculation/timeYield",
    method: "post",
    data: {
        time,
        machineCode,
        suShiftId,
        postId,
        corpUsersId,
        suGroupId,
        changeStartDate,
        changeEndDate
    }
})
