var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        background: "transparent",
        position: "relative",
        top: "70px",
        left: "0"
      },
      attrs: { id: "home_container" }
    },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title + "--" + _vm.codesTitle,
                visible: _vm.dialogVisible,
                fullscreen: "",
                width: "60%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { "margin-top": "-20px" },
                  attrs: { model: _vm.formInline, "label-width": "120px" }
                },
                [
                  _c(
                    "el-collapse",
                    {
                      model: {
                        value: _vm.activeNames,
                        callback: function($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "替岗明细", name: "3" } },
                        [
                          _c(
                            "el-row",
                            [
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "right"
                                    },
                                    attrs: {
                                      xs: 24,
                                      sm: 8,
                                      md: 8,
                                      lg: 8,
                                      xl: 4
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "0px" },
                                        attrs: { type: "primary" },
                                        on: { click: _vm.addRecord }
                                      },
                                      [_vm._v("添加记录\n                ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "0px" },
                                        attrs: { type: "primary" },
                                        on: { click: _vm.removeRecord }
                                      },
                                      [_vm._v("移除记录\n                ")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-table",
                                  {
                                    ref: "singleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.timeYieldData1,
                                      "highlight-current-row": ""
                                    },
                                    on: {
                                      "current-change": _vm.handleCurrentChange
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "shiftName",
                                        label: "班制",
                                        width: "180"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "groupName",
                                        label: "班组",
                                        width: "180"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "machineCode",
                                        width: "180",
                                        label: "设备"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "userName",
                                        width: "180",
                                        label: "人员名称"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "postName",
                                        width: "180",
                                        label: "岗位"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "startTime",
                                        width: "180",
                                        label: "开始时间"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "endTime",
                                        width: "180",
                                        label: "结束时间"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "startIotNumber",
                                        width: "180",
                                        label: "开始设备米数"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      Number(
                                                        scope.row.startIotNumber
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2271295246
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "endIotNumber",
                                        width: "180",
                                        label: "结束设备米数"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      scope.row.endIotNumber
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3827222051
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "yield",
                                        width: "180",
                                        label: "预估产量"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "30%",
                    visible: _vm.innerVisible,
                    "append-to-body": ""
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.innerVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "inner" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.nameRadio,
                            callback: function($$v) {
                              _vm.nameRadio = $$v
                            },
                            expression: "nameRadio"
                          }
                        },
                        _vm._l(_vm.nameList, function(item) {
                          return _c("el-radio-button", {
                            key: item.id,
                            attrs: { label: item.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.innerVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.selectName }
                        },
                        [_vm._v(_vm._s(_vm.$t("Yes")))]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "30%",
                    visible: _vm.showAddUser,
                    "append-to-body": ""
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.showAddUser = $event
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "inner" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: { model: _vm.form, "label-width": "110px" }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.newUserForm.name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newUserForm,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "newUserForm.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "性别" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.newUserForm.gender,
                                    callback: function($$v) {
                                      _vm.$set(_vm.newUserForm, "gender", $$v)
                                    },
                                    expression: "newUserForm.gender"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("男")
                                  ]),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("女")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.newUserForm.phoneNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newUserForm,
                                      "phoneNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "newUserForm.phoneNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.newUserForm.status,
                                    callback: function($$v) {
                                      _vm.$set(_vm.newUserForm, "status", $$v)
                                    },
                                    expression: "newUserForm.status"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("在职")
                                  ]),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("离职")
                                  ]),
                                  _c("el-radio", { attrs: { label: -1 } }, [
                                    _vm._v("待确认")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showAddUser = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addUser }
                        },
                        [_vm._v(_vm._s(_vm.$t("Yes")))]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "30%",
                    visible: _vm.innerSave,
                    "append-to-body": ""
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.innerSave = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "inner" }, [
                    _c(
                      "h4",
                      { staticStyle: { "margin-bottom": "0.8125rem" } },
                      [_vm._v("以下为可修改机器")]
                    ),
                    _c(
                      "div",
                      { staticClass: "content" },
                      _vm._l(_vm.codeList, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "item",
                            staticStyle: { "margin-bottom": "0.8125rem" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.code) +
                                "\n          "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.innerSave = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveForPostRlus }
                        },
                        [_vm._v(_vm._s(_vm.$t("Yes")))]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "margin-top": "-30px" },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.closedialogVisible } }, [
                    _vm._v(_vm._s(_vm.$t("Cancel")))
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "outFormDiv",
          staticStyle: {
            display: "flex",
            padding: "15px",
            background: "#f2f2f2",
            position: "fixed",
            top: "100px",
            right: "0",
            width: "calc(100% - 13.75rem)",
            "z-index": "1",
            "box-sizing": "border-box"
          }
        },
        [
          _vm.setData.topIsMenu
            ? _c(
                "div",
                { staticClass: "formList" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      staticStyle: { display: "flex" },
                      attrs: {
                        inline: true,
                        model: _vm.formInline,
                        labelWidth: "180px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(100% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期"
                            },
                            model: {
                              value: _vm.formInline.time,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "time", $$v)
                              },
                              expression: "formInline.time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(100% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择车间"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.workChange(
                                    _vm.formInline.workShopId
                                  )
                                }
                              },
                              model: {
                                value: _vm.formInline.workShopId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "workShopId", $$v)
                                },
                                expression: "formInline.workShopId"
                              }
                            },
                            _vm._l(_vm.workCenter, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(100% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择工序"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.queryShiftOptions(
                                    _vm.formInline.processId
                                  )
                                }
                              },
                              model: {
                                value: _vm.formInline.processId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "processId", $$v)
                                },
                                expression: "formInline.processId"
                              }
                            },
                            _vm._l(_vm.processIData, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(100% - 23.33px)",
                            display: "flex"
                          },
                          attrs: { label: "" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择班制" },
                              model: {
                                value: _vm.shiftNameValue,
                                callback: function($$v) {
                                  _vm.shiftNameValue = $$v
                                },
                                expression: "shiftNameValue"
                              }
                            },
                            _vm._l(_vm.shiftOptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(100% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入人员姓名" },
                            model: {
                              value: _vm.formInline.userName,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "userName", $$v)
                              },
                              expression: "formInline.userName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(100% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入设备编号" },
                            model: {
                              value: _vm.formInline.machineCode,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "machineCode", $$v)
                              },
                              expression: "formInline.machineCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.setData.topIsMenu
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  staticStyle: {
                    width: "550px",
                    "text-align": "right",
                    display: "flex",
                    "justify-content": "flex-end",
                    "align-items": "start"
                  }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSearch } },
                    [_vm._v(_vm._s(_vm.$t("search")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.setData.topIsCheMenu
            ? _c(
                "div",
                { staticStyle: { width: "100%", "text-align": "right" } },
                [
                  _c(
                    "el-checkbox-button",
                    {
                      staticClass: "allBtn",
                      attrs: {
                        indeterminate: _vm.isIndeterminate,
                        type: "primary"
                      },
                      on: { change: _vm.onAll }
                    },
                    [_vm._v(_vm._s(_vm.setData.allText) + "\n      ")]
                  ),
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-right": "5px" },
                      attrs: { value: _vm.checkboxYes.length }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v(_vm._s(_vm.$t("Batch for duty")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.outAll } },
                    [_vm._v(_vm._s(_vm.$t("Exit the batch")))]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm.list.length > 0
        ? _c(
            "div",
            {
              staticClass: "rygl",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                width: "100%",
                position: "relative",
                padding: "7.5px",
                "box-sizing": "border-box"
              },
              style: _vm.outHei
            },
            [
              _c(
                "draggable",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    "margin-top": "7.5px",
                    display: "flex",
                    "flex-wrap": "wrap",
                    padding: "0 7.5px",
                    "box-sizing": "border-box"
                  },
                  style: _vm.scHei
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        "flex-wrap": "wrap"
                      },
                      model: {
                        value: _vm.checkboxYes,
                        callback: function($$v) {
                          _vm.checkboxYes = $$v
                        },
                        expression: "checkboxYes"
                      }
                    },
                    _vm._l(_vm.list, function(item, index) {
                      return _c(
                        "el-checkbox-button",
                        {
                          key: item.machineCode,
                          staticClass: "fontCss",
                          staticStyle: { padding: "0" },
                          style: _vm.itemHeight,
                          attrs: {
                            label: item.machineCode,
                            disabled: _vm.setData.isCheck
                          },
                          on: { change: _vm.isIndeterminateChange }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                padding: "0 7.5px",
                                height: "50px",
                                "line-height": "50px",
                                "border-radius": "8px 8px 0 0",
                                width: "100%",
                                "box-sizing": "border-box"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { position: "relative" } },
                                [_vm._v(_vm._s(item.machineCode))]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "0 7.5px",
                                height: "calc(100% - 128px)",
                                display: "flex",
                                "flex-wrap": "wrap",
                                "align-items": "center",
                                width: "100%",
                                "box-sizing": "border-box",
                                "text-align": "left"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-pinzhong12",
                                    staticStyle: {
                                      "vertical-align": "0",
                                      "font-size": "16px !important",
                                      color: "#cad9ef",
                                      "margin-right": "10px"
                                    }
                                  }),
                                  _vm._v("人员姓名：\n              "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 115px)",
                                        "text-overflow": "ellipsis",
                                        overflow: "hidden",
                                        "white-space": "nowrap"
                                      }
                                    },
                                    _vm._l(item.machineUserPostList, function(
                                      val
                                    ) {
                                      return _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#aaa",
                                            "padding-right": "5px"
                                          }
                                        },
                                        [_vm._v(_vm._s(val.userName))]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-mingxi",
                                    staticStyle: {
                                      "vertical-align": "0",
                                      "font-size": "16px !important",
                                      color: "#cad9ef",
                                      "margin-right": "10px"
                                    }
                                  }),
                                  _vm._v("工       种：\n              "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 115px)",
                                        "text-overflow": "ellipsis",
                                        overflow: "hidden",
                                        "white-space": "nowrap"
                                      }
                                    },
                                    _vm._l(item.machineUserPostList, function(
                                      val
                                    ) {
                                      return _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#aaa",
                                            "padding-right": "5px"
                                          }
                                        },
                                        [_vm._v(_vm._s(val.postName))]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                padding: "0 7.5px",
                                "justify-content": "space-between",
                                "align-items": "center",
                                width: "100%",
                                height: "50px",
                                "box-sizing": "border-box"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { width: "calc(50% - 23.75px)" }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "#f0f7ff",
                                        width: "100%",
                                        border: "1px solid #4d8af0",
                                        color: "#4d8af0",
                                        "border-radius": "8px",
                                        padding: "12px 0"
                                      },
                                      attrs: {
                                        icon: "iconfont icon-duoren",
                                        type: _vm.boxStyle(index)
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openForPost(
                                            item.machineCode,
                                            item.machineId,
                                            item.machineUserPostList,
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                替岗\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "fixed",
                        right: "15px",
                        bottom: "70px"
                      }
                    },
                    [
                      _vm.isTop
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                cursor: "pointer",
                                width: "50px",
                                "border-radius": "50%",
                                height: "50px",
                                background: "#4d8af0",
                                "text-align": "center",
                                "line-height": "50px"
                              },
                              on: { click: _vm.toTop }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-zhiding-59",
                                staticStyle: {
                                  "font-size": "30px !important",
                                  color: "#fff",
                                  margin: "0",
                                  width: "50px"
                                }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm.isPre
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                cursor: "pointer",
                                width: "50px",
                                "border-radius": "50%",
                                height: "50px",
                                background: "#4d8af0",
                                "text-align": "center",
                                "line-height": "50px"
                              },
                              on: { click: _vm.preList }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-shang25",
                                staticStyle: {
                                  "font-size": "30px !important",
                                  color: "#fff",
                                  margin: "-7px 0 0",
                                  width: "50px"
                                }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm.isNex
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                cursor: "pointer",
                                width: "50px",
                                "border-radius": "50%",
                                height: "50px",
                                background: "#4d8af0",
                                "text-align": "center",
                                "line-height": "50px"
                              },
                              on: { click: _vm.nextList }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-xia852",
                                staticStyle: {
                                  "font-size": "30px !important",
                                  color: "#fff",
                                  margin: "0",
                                  width: "50px"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", [
            _c("div", { staticClass: "el-empty" }, [
              _c("div", { staticClass: "el-empty__image" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      viewBox: "0 0 79 86",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink"
                    }
                  },
                  [
                    _c(
                      "defs",
                      [
                        _c(
                          "linearGradient",
                          {
                            attrs: {
                              id: "linearGradient-1-1",
                              x1: "38.8503086%",
                              y1: "0%",
                              x2: "61.1496914%",
                              y2: "100%"
                            }
                          },
                          [
                            _c("stop", {
                              attrs: { "stop-color": "#FCFCFD", offset: "0%" }
                            }),
                            _c("stop", {
                              attrs: { "stop-color": "#EEEFF3", offset: "100%" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "linearGradient",
                          {
                            attrs: {
                              id: "linearGradient-2-1",
                              x1: "0%",
                              y1: "9.5%",
                              x2: "100%",
                              y2: "90.5%"
                            }
                          },
                          [
                            _c("stop", {
                              attrs: { "stop-color": "#FCFCFD", offset: "0%" }
                            }),
                            _c("stop", {
                              attrs: { "stop-color": "#E9EBEF", offset: "100%" }
                            })
                          ],
                          1
                        ),
                        _c("rect", {
                          attrs: {
                            id: "path-3-1",
                            x: "0",
                            y: "0",
                            width: "17",
                            height: "36"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Illustrations",
                          stroke: "none",
                          "stroke-width": "1",
                          fill: "none",
                          "fill-rule": "evenodd"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "B-type",
                              transform: "translate(-1268.000000, -535.000000)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Group-2",
                                  transform:
                                    "translate(1268.000000, 535.000000)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Oval-Copy-2",
                                    d:
                                      "M39.5,86 C61.3152476,86 79,83.9106622 79,81.3333333 C79,78.7560045 57.3152476,78 35.5,78 C13.6847524,78 0,78.7560045 0,81.3333333 C0,83.9106622 17.6847524,86 39.5,86 Z",
                                    fill: "#F7F8FC"
                                  }
                                }),
                                _c("polygon", {
                                  attrs: {
                                    id: "Rectangle-Copy-14",
                                    fill: "#E5E7E9",
                                    transform:
                                      "translate(27.500000, 51.500000) scale(1, -1) translate(-27.500000, -51.500000) ",
                                    points: "13 58 53 58 42 45 2 45"
                                  }
                                }),
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Group-Copy",
                                      transform:
                                        "translate(34.500000, 31.500000) scale(-1, 1) rotate(-25.000000) translate(-34.500000, -31.500000) translate(7.000000, 10.000000)"
                                    }
                                  },
                                  [
                                    _c("polygon", {
                                      attrs: {
                                        id: "Rectangle-Copy-10",
                                        fill: "#E5E7E9",
                                        transform:
                                          "translate(11.500000, 5.000000) scale(1, -1) translate(-11.500000, -5.000000) ",
                                        points: "2.84078316e-14 3 18 3 23 7 5 7"
                                      }
                                    }),
                                    _c("polygon", {
                                      attrs: {
                                        id: "Rectangle-Copy-11",
                                        fill: "#EDEEF2",
                                        points:
                                          "-3.69149156e-15 7 38 7 38 43 -3.69149156e-15 43"
                                      }
                                    }),
                                    _c("rect", {
                                      attrs: {
                                        id: "Rectangle-Copy-12",
                                        fill: "url(#linearGradient-1-1)",
                                        transform:
                                          "translate(46.500000, 25.000000) scale(-1, 1) translate(-46.500000, -25.000000) ",
                                        x: "38",
                                        y: "7",
                                        width: "17",
                                        height: "36"
                                      }
                                    }),
                                    _c("polygon", {
                                      attrs: {
                                        id: "Rectangle-Copy-13",
                                        fill: "#F8F9FB",
                                        transform:
                                          "translate(39.500000, 3.500000) scale(-1, 1) translate(-39.500000, -3.500000) ",
                                        points:
                                          "24 7 41 7 55 -3.63806207e-12 38 -3.63806207e-12"
                                      }
                                    })
                                  ]
                                ),
                                _c("rect", {
                                  attrs: {
                                    id: "Rectangle-Copy-15",
                                    fill: "url(#linearGradient-2-1)",
                                    x: "13",
                                    y: "45",
                                    width: "40",
                                    height: "36"
                                  }
                                }),
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Rectangle-Copy-17",
                                      transform:
                                        "translate(53.000000, 45.000000)"
                                    }
                                  },
                                  [
                                    _c(
                                      "mask",
                                      {
                                        attrs: { id: "mask-4-1", fill: "white" }
                                      },
                                      [
                                        _c("use", {
                                          attrs: { "xlink:href": "#path-3-1" }
                                        })
                                      ]
                                    ),
                                    _c("use", {
                                      attrs: {
                                        id: "Mask",
                                        fill: "#E0E3E9",
                                        transform:
                                          "translate(8.500000, 18.000000) scale(-1, 1) translate(-8.500000, -18.000000) ",
                                        "xlink:href": "#path-3-1"
                                      }
                                    }),
                                    _c("polygon", {
                                      attrs: {
                                        id: "Rectangle-Copy",
                                        fill: "#D5D7DE",
                                        mask: "url(#mask-4-1)",
                                        transform:
                                          "translate(12.000000, 9.000000) scale(-1, 1) translate(-12.000000, -9.000000) ",
                                        points:
                                          "7 0 24 0 20 18 -1.70530257e-13 16"
                                      }
                                    })
                                  ]
                                ),
                                _c("polygon", {
                                  attrs: {
                                    id: "Rectangle-Copy-18",
                                    fill: "#F8F9FB",
                                    transform:
                                      "translate(66.000000, 51.500000) scale(-1, 1) translate(-66.000000, -51.500000) ",
                                    points: "62 45 79 45 70 58 53 58"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _vm._m(0)
            ])
          ]),
      _c(
        "div",
        [_c("add", { ref: "add", on: { children: _vm.parentAdd } })],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { marginTop: "50px" },
          attrs: {
            option: _vm.tableOption,
            data: _vm.tableData,
            title: _vm.$t("Add record"),
            width: "60%",
            "append-to-body": true,
            visible: _vm.dialogVisible2,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible2 = $event
            }
          }
        },
        [
          _c(
            "div",
            {},
            [
              _vm.currentRow
                ? _c(
                    "el-form",
                    {
                      ref: "shiftForm",
                      attrs: { model: _vm.currentRow, "label-width": "120px" }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "设备" }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "设备" },
                                model: {
                                  value: _vm.currentRow.machineCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.currentRow, "machineCode", $$v)
                                  },
                                  expression: "currentRow.machineCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1", "margin-left": "10px" },
                              attrs: { label: "开始时间" }
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "开始时间",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "picker-options": _vm.startPickerOptions
                                },
                                model: {
                                  value: _vm.currentRow.startTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.currentRow, "startTime", $$v)
                                  },
                                  expression: "currentRow.startTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "班组" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择班组" },
                                  on: { change: _vm.getUserName },
                                  model: {
                                    value: _vm.currentRow.groupId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.currentRow, "groupId", $$v)
                                    },
                                    expression: "currentRow.groupId"
                                  }
                                },
                                _vm._l(_vm.workShopOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1", "margin-left": "10px" },
                              attrs: { label: "结束时间" }
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetime",
                                  disabled: "",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "结束时间"
                                },
                                model: {
                                  value: _vm.currentRow.endTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.currentRow, "endTime", $$v)
                                  },
                                  expression: "currentRow.endTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "岗位" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择岗位" },
                                  model: {
                                    value: _vm.currentRow.postId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.currentRow, "postId", $$v)
                                    },
                                    expression: "currentRow.postId"
                                  }
                                },
                                _vm._l(_vm.postOptions, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1", "margin-left": "10px" },
                              attrs: { label: "开始设备米数" }
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "开始设备米数",
                                  "controls-position": "right",
                                  min: _vm.tempNum,
                                  max: _vm.currentRow.endIotNumber
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getYield(_vm.currentRow)
                                  }
                                },
                                model: {
                                  value: _vm.currentRow.startIotNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentRow,
                                      "startIotNumber",
                                      $$v
                                    )
                                  },
                                  expression: "currentRow.startIotNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "人员名称" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择人员" },
                                  model: {
                                    value: _vm.currentRow.userId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.currentRow, "userId", $$v)
                                    },
                                    expression: "currentRow.userId"
                                  }
                                },
                                _vm._l(_vm.userOptions, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.userId
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1", "margin-left": "10px" },
                              attrs: { label: "结束设备米数" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "结束设备米数",
                                  disabled: "",
                                  type: "number"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getYield(_vm.currentRow)
                                  }
                                },
                                model: {
                                  value: _vm.currentRow.endIotNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentRow,
                                      "endIotNumber",
                                      $$v
                                    )
                                  },
                                  expression: "currentRow.endIotNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "预估产量" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  readOnly: "",
                                  placeholder: "预估产量"
                                },
                                model: {
                                  value: _vm.currentRow.yield,
                                  callback: function($$v) {
                                    _vm.$set(_vm.currentRow, "yield", $$v)
                                  },
                                  expression: "currentRow.yield"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible2 = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.insertBtn } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-empty__description" }, [
      _c("p", [_vm._v("暂无数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }