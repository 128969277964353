<template>
  <div id="home_container" style="background: transparent; position: relative; top: 70px; left: 0">
    <el-dialog
        :title="title + '--' + codesTitle"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        fullscreen
        width="60%"
    >
      <el-form ref="form" :model="formInline" style="margin-top: -20px" label-width="120px">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="替岗明细" name="3">
            <el-row>
              <template>
                <!--                <el-col class="formDiv" :sm="12" :md="10" :lg="8" :xl="6">-->
                <!--                </el-col>-->
                <!--                <el-col class="formDiv" :sm="12" :md="10" :lg="8" :xl="6">-->
                <!--                </el-col>-->
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="4" style="width:100%; text-align: right">
                  <!--                    <el-select v-model="value" placeholder="请选择班制">-->
                  <!--                      <el-option-->
                  <!--                          v-for="(item, index) in shiftOptions"-->
                  <!--                          :key="index"-->
                  <!--                          :label="item.name"-->
                  <!--                          :value="item.id">-->
                  <!--                      </el-option>-->
                  <!--                    </el-select>-->
                  <el-button type="primary" style="margin-right:0px;" @click="addRecord">添加记录
                  </el-button>

                  <el-button type="primary" style="margin-right:0px;" @click="removeRecord">移除记录
                  </el-button>
                </el-col>
                <el-table
                    ref="singleTable"
                    :data="timeYieldData1"
                    highlight-current-row
                    @current-change="handleCurrentChange"
                    style="width: 100%"
                >
                  <el-table-column
                      prop="shiftName"
                      label="班制"
                      width="180">

                  </el-table-column>
                  <el-table-column
                      prop="groupName"
                      label="班组"
                      width="180">
                  </el-table-column>
                  <el-table-column
                      prop="machineCode"
                      width="180"
                      label="设备">
                  </el-table-column>
                  <el-table-column
                      prop="userName"
                      width="180"
                      label="人员名称">
                  </el-table-column>
                  <el-table-column
                      prop="postName"
                      width="180"
                      label="岗位">
                  </el-table-column>
                  <el-table-column
                      prop="startTime"
                      width="180"
                      label="开始时间">
                  </el-table-column>
                  <el-table-column
                      prop="endTime"
                      width="180"
                      label="结束时间">
                  </el-table-column>
                  <el-table-column
                      prop="startIotNumber"
                      width="180"
                      label="开始设备米数">
                    <template slot-scope="scope">
                      {{ Number(scope.row.startIotNumber) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="endIotNumber"
                      width="180"
                      label="结束设备米数">
                    <template slot-scope="scope">
                      {{ scope.row.endIotNumber }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="yield"
                      width="180"
                      label="预估产量">
                  </el-table-column>
                </el-table>
              </template>
            </el-row>
            <!--            <el-row>
                          <el-col :span="4" style="margin-right: 50px">
                            <span style="margin-right: 10px">时间</span>
                          </el-col>
                          <el-col :span="4">
                            <span>生产品种</span>
                          </el-col>
                          <el-col :span="4">
                            <span>品种产量</span>
                          </el-col>
                          <el-col :span="4">
                            <span>被替岗人产量</span>
                          </el-col>
                          <el-col :span="4">
                            <span>替岗人产量</span>
                          </el-col>
                        </el-row>
                        <el-row  v-for="(item,index) in timeYieldData">
                          <el-col :span="4" style="margin-right: 50px">
                            <span style="margin-right: 10px">{{item.timeInterval}}</span>
                          </el-col>
                          <el-col :span="4">
                            <span>{{item.product}}</span>
                          </el-col>
                          <el-col :span="4">
                            <span>{{item.yield}}</span>
                          </el-col>
                          <el-col :span="4">
                            <el-input-number v-if="item.flag == 1" :min=0 disabled v-model="item.byReplacedData"></el-input-number>
                          </el-col>
                          <el-col :span="4">
                            <el-input-number v-if="item.flag == 1" :min=0 :max="item.yield" :step="item.yield" step-strictly v-model="item.replaceData" @change="replaceDataChange(item)"></el-input-number>
                          </el-col>
                        </el-row>-->
            <!--            -->
            <!--              <template >-->
            <!--                -->
            <!--                <el-row>-->
            <!--                  <el-col :span="3" style="margin-right: 50px">-->
            <!--                    <el-input-number  v-model="timeYieldData[index].yield"  style="float: left;margin-right: 10px"></el-input-number>-->
            <!--                    <span>kg</span>-->
            <!--                  </el-col>-->
            <!--                  <el-col :span="17">-->
            <!--                    <span style="float: left">生产品种：</span>-->
            <!--                    <el-input v-model="timeYieldData[index].product" rows="2" disabled style="width: 150px"></el-input>-->
            <!--                  </el-col>-->
            <!--                </el-row>-->
            <!--              </template>-->
            <!--            </el-form-item>-->
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <el-dialog width="30%" :visible.sync="innerVisible" append-to-body>
        <div class="inner">
          <el-radio-group v-model="nameRadio">
            <el-radio-button
                v-for="item in nameList"
                :key="item.id"
                :label="item.name"
            >
            </el-radio-button>
          </el-radio-group>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px">
          <el-button @click="innerVisible = false">{{ $t("Cancel") }}</el-button>
          <el-button type="primary" @click="selectName">{{ $t("Yes") }}</el-button>
        </p>
      </el-dialog>
      <el-dialog width="30%" :visible.sync="showAddUser" append-to-body>
        <div class="inner">
          <el-form ref="form" :model="form" label-width="110px">
            <el-form-item label="姓名">
              <el-input v-model.trim="newUserForm.name"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-radio-group v-model="newUserForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model.trim="newUserForm.phoneNumber"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="newUserForm.status">
                <el-radio :label="0">在职</el-radio>
                <el-radio :label="1">离职</el-radio>
                <el-radio :label="-1">待确认</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px">
          <el-button @click="showAddUser = false">{{ $t("Cancel") }}</el-button>
          <el-button type="primary" @click="addUser">{{ $t("Yes") }}</el-button>
        </p>
      </el-dialog>
      <el-dialog width="30%" :visible.sync="innerSave" append-to-body>
        <div class="inner">
          <h4 style="margin-bottom: 0.8125rem">以下为可修改机器</h4>
          <div class="content">
            <div
                class="item"
                style="margin-bottom: 0.8125rem"
                v-for="item in codeList"
                :key="item.id"
            >
              {{ item.code }}
            </div>
          </div>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px">
          <el-button @click="innerSave = false">{{ $t("Cancel") }}</el-button>
          <el-button type="primary" @click="saveForPostRlus">{{ $t("Yes") }}</el-button>
        </p>
      </el-dialog>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px">
        <el-button @click="closedialogVisible">{{ $t("Cancel") }}</el-button>
        <el-button type="primary" @click="confirm">{{ $t("Yes") }}</el-button>
      </p>
    </el-dialog>
    <div
        class="outFormDiv"
        style="
        display: flex;
        padding: 15px;
        background: #f2f2f2;
        position: fixed;
        top: 100px;
        right: 0;
        width: calc(100% - 13.75rem);
        z-index: 1;
        box-sizing: border-box;
      "
    >
      <div
          class="formList"
          v-if="setData.topIsMenu"

      >
        <el-form :inline="true"
                 :model="formInline"
                 class="demo-form-inline"
                 labelWidth="180px"
                 style="display: flex;">
          <el-form-item
              style="width: calc(100% - 23.33px); display: flex">
            <el-date-picker v-model="formInline.time"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期"
                            style="width: 100%">
            </el-date-picker>
          </el-form-item>
          <el-form-item
              style="width: calc(100% - 23.33px); display: flex">
            <el-select filterable @change="workChange(formInline.workShopId)"
                       v-model="formInline.workShopId"
                       placeholder="请选择车间">
              <el-option v-for="(item, index) in workCenter"
                         :key="index"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              style="width: calc(100% - 23.33px); display: flex">
            <el-select filterable
                       @change="queryShiftOptions(formInline.processId)"
                       v-model="formInline.processId"
                       placeholder="请选择工序">
              <el-option v-for="item in processIData"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=""
                        style="width: calc(100% - 23.33px); display: flex">
            <el-select v-model="shiftNameValue" placeholder="请选择班制">
              <el-option
                  v-for="item in shiftOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              style="width: calc(100% - 23.33px); display: flex">
            <el-input v-model="formInline.userName" placeholder="请输入人员姓名"></el-input>
          </el-form-item>
          <el-form-item
              style="width: calc(100% - 23.33px); display: flex">
            <el-input v-model="formInline.machineCode" placeholder="请输入设备编号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div
          class="btn"
          v-if="setData.topIsMenu"
          style="width: 550px; text-align: right; display: flex; justify-content: flex-end; align-items: start;">
        <el-button type="primary" @click="onSearch">{{ $t("search") }}</el-button>
        <!--<el-checkbox-button class="allBtn" :indeterminate="isIndeterminate" type="primary" @change="onAll">{{setData.allText}}</el-checkbox-button>-->
        <!--        <el-button type="primary" @click="getAll">{{$t("Batch for duty")}}</el-button>-->
        <!-- <el-button type="primary" @click="daochu">{{$t("EXPORT")}}</el-button> -->
      </div>
      <div v-if="setData.topIsCheMenu" style="width: 100%; text-align: right">
        <el-checkbox-button
            class="allBtn"
            :indeterminate="isIndeterminate"
            type="primary"
            @change="onAll"
        >{{ setData.allText }}
        </el-checkbox-button
        >
        <!--<el-button type="primary" @click="onAll" style="margin-right:5px;">{{setData.allText}}</el-button>-->
        <el-badge
            :value="checkboxYes.length"
            class="item"
            style="margin-right: 5px"
        >
          <el-button type="primary" @click="onSubmit">{{ $t("Batch for duty") }}</el-button>
        </el-badge>
        <el-button type="primary" @click="outAll">{{ $t("Exit the batch") }}</el-button>
      </div>
    </div>

    <div v-if="list.length > 0"
         class="rygl"
         style="
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        padding: 7.5px;
        box-sizing: border-box;
      "
         :style="outHei"
    >
      <draggable
          style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          margin-top: 7.5px;
          display: flex;
          flex-wrap: wrap;
          padding: 0 7.5px;
          box-sizing: border-box;
        "
          :style="scHei"
      >
        <el-checkbox-group
            v-model="checkboxYes"
            style="width: 100%; display: flex; flex-wrap: wrap"
        >
          <el-checkbox-button
              v-for="(item, index) in list"
              :label="item.machineCode"
              :key="item.machineCode"
              style="padding: 0"
              :style="itemHeight"
              class="fontCss"
              @change="isIndeterminateChange"
              :disabled="setData.isCheck"
          >
            <div
                style="
                display: flex;
                justify-content: space-between;
                padding: 0 7.5px;
                height: 50px;
                line-height: 50px;
                border-radius: 8px 8px 0 0;
                width: 100%;
                box-sizing: border-box;
              "
                class="name"
            >
              <div style="position: relative">{{ item.machineCode }}</div>
            </div>
            <div
                style="
                padding: 0 7.5px;
                height: calc(100% - 128px);
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                text-align: left;
              "
            >
              <div style="width: 100%;display:flex;align-items:center;">
                <i
                    class="iconfont icon-pinzhong12"
                    style="
                    vertical-align: 0;
                    font-size: 16px !important;
                    color: #cad9ef;
                    margin-right: 10px;
                  "
                ></i
                >人员姓名：
                <div style="width:calc(100% - 115px);text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
                  <span style="font-size: 14px; color: #aaa; padding-right: 5px"
                        v-for="val in item.machineUserPostList">{{ val.userName }}</span>
                </div>
              </div>
              <div style="width: 100%;display:flex;align-items:center;">
                <i
                    class="iconfont icon-mingxi"
                    style="
                    vertical-align: 0;
                    font-size: 16px !important;
                    color: #cad9ef;
                    margin-right: 10px;
                  "
                ></i
                >工&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;种：
                <div style="width:calc(100% - 115px);text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
                  <span style="font-size: 14px; color: #aaa; padding-right: 5px"
                        v-for="val in item.machineUserPostList">{{ val.postName }}</span>
                </div>
              </div>
            </div>
            <div
                style="
                display: flex;
                padding: 0 7.5px;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 50px;
                box-sizing: border-box;
              "
            >
              <div style="width: calc(50% - 23.75px)">
                <el-button
                    style="
                    background: #f0f7ff;
                    width: 100%;
                    border: 1px solid #4d8af0;
                    color: #4d8af0;
                    border-radius: 8px;
                    padding: 12px 0;
                  "
                    icon="iconfont icon-duoren"
                    :type="boxStyle(index)"
                    @click="
                    openForPost(
                      item.machineCode,
                      item.machineId,
                      item.machineUserPostList,
                      item
                    )
                  "
                >
                  替岗
                </el-button>
              </div>
            </div>
          </el-checkbox-button>
        </el-checkbox-group>
        <!--<div v-for="(item,indexCod,i) in list" :key="indexCod"
                     style="width: 20%;height: 163px;background: rgba(242, 242, 242, 1);float: left;margin: 25px;">
                    <p style="fontSize: 35px;textAlign: center;">
                        {{ indexCod }}
                        <el-checkbox @change="isIndeterminateChange" v-model="checkboxYes" style="float: left;" :label="indexCod">
                            {{ "" }}
                        </el-checkbox>
                    </p>

                    <footer>-->
        <!-- 小方块 -->

        <!--<div v-for="(value,index) in item"
                             style="width: 30%;height: 50px;margin: 4px;float:left;color:white;border-radius: 10px;">
                            <el-button :type="boxStyle(index)" @click="openForPost(indexCod,value.id,value,item)">{{value.name}}</el-button>
                        </div>
                    </footer>

                </div>-->
        <div style="position: fixed; right: 15px; bottom: 70px">
          <div
              v-if="isTop"
              @click="toTop"
              style="
              margin-bottom: 10px;
              cursor: pointer;
              width: 50px;
              border-radius: 50%;
              height: 50px;
              background: #4d8af0;
              text-align: center;
              line-height: 50px;
            "
          >
            <i
                class="iconfont icon-zhiding-59"
                style="
                font-size: 30px !important;
                color: #fff;
                margin: 0;
                width: 50px;
              "
            ></i>
          </div>
          <div
              v-if="isPre"
              @click="preList"
              style="
              margin-bottom: 10px;
              cursor: pointer;
              width: 50px;
              border-radius: 50%;
              height: 50px;
              background: #4d8af0;
              text-align: center;
              line-height: 50px;
            "
          >
            <i
                class="iconfont icon-shang25"
                style="
                font-size: 30px !important;
                color: #fff;
                margin: -7px 0 0;
                width: 50px;
              "
            ></i>
          </div>
          <div
              v-if="isNex"
              @click="nextList"
              style="
              margin-bottom: 10px;
              cursor: pointer;
              width: 50px;
              border-radius: 50%;
              height: 50px;
              background: #4d8af0;
              text-align: center;
              line-height: 50px;
            "
          >
            <i
                class="iconfont icon-xia852"
                style="
                font-size: 30px !important;
                color: #fff;
                margin: 0;
                width: 50px;
              "
            ></i>
          </div>
        </div>
      </draggable>
    </div>
    <div v-else>
      <div class="el-empty">
        <div class="el-empty__image">
          <svg viewBox="0 0 79 86" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <linearGradient id="linearGradient-1-1" x1="38.8503086%" y1="0%" x2="61.1496914%" y2="100%">
                <stop stop-color="#FCFCFD" offset="0%"></stop>
                <stop stop-color="#EEEFF3" offset="100%"></stop>
              </linearGradient>
              <linearGradient id="linearGradient-2-1" x1="0%" y1="9.5%" x2="100%" y2="90.5%">
                <stop stop-color="#FCFCFD" offset="0%"></stop>
                <stop stop-color="#E9EBEF" offset="100%"></stop>
              </linearGradient>
              <rect id="path-3-1" x="0" y="0" width="17" height="36"></rect>
            </defs>
            <g id="Illustrations" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="B-type" transform="translate(-1268.000000, -535.000000)">
                <g id="Group-2" transform="translate(1268.000000, 535.000000)">
                  <path id="Oval-Copy-2"
                        d="M39.5,86 C61.3152476,86 79,83.9106622 79,81.3333333 C79,78.7560045 57.3152476,78 35.5,78 C13.6847524,78 0,78.7560045 0,81.3333333 C0,83.9106622 17.6847524,86 39.5,86 Z"
                        fill="#F7F8FC"></path>
                  <polygon id="Rectangle-Copy-14" fill="#E5E7E9"
                           transform="translate(27.500000, 51.500000) scale(1, -1) translate(-27.500000, -51.500000) "
                           points="13 58 53 58 42 45 2 45"></polygon>
                  <g id="Group-Copy"
                     transform="translate(34.500000, 31.500000) scale(-1, 1) rotate(-25.000000) translate(-34.500000, -31.500000) translate(7.000000, 10.000000)">
                    <polygon id="Rectangle-Copy-10" fill="#E5E7E9"
                             transform="translate(11.500000, 5.000000) scale(1, -1) translate(-11.500000, -5.000000) "
                             points="2.84078316e-14 3 18 3 23 7 5 7"></polygon>
                    <polygon id="Rectangle-Copy-11" fill="#EDEEF2"
                             points="-3.69149156e-15 7 38 7 38 43 -3.69149156e-15 43"></polygon>
                    <rect id="Rectangle-Copy-12" fill="url(#linearGradient-1-1)"
                          transform="translate(46.500000, 25.000000) scale(-1, 1) translate(-46.500000, -25.000000) "
                          x="38" y="7" width="17" height="36"></rect>
                    <polygon id="Rectangle-Copy-13" fill="#F8F9FB"
                             transform="translate(39.500000, 3.500000) scale(-1, 1) translate(-39.500000, -3.500000) "
                             points="24 7 41 7 55 -3.63806207e-12 38 -3.63806207e-12"></polygon>
                  </g>
                  <rect id="Rectangle-Copy-15" fill="url(#linearGradient-2-1)" x="13" y="45" width="40"
                        height="36"></rect>
                  <g id="Rectangle-Copy-17" transform="translate(53.000000, 45.000000)">
                    <mask id="mask-4-1" fill="white">
                      <use xlink:href="#path-3-1"></use>
                    </mask>
                    <use id="Mask" fill="#E0E3E9"
                         transform="translate(8.500000, 18.000000) scale(-1, 1) translate(-8.500000, -18.000000) "
                         xlink:href="#path-3-1"></use>
                    <polygon id="Rectangle-Copy" fill="#D5D7DE" mask="url(#mask-4-1)"
                             transform="translate(12.000000, 9.000000) scale(-1, 1) translate(-12.000000, -9.000000) "
                             points="7 0 24 0 20 18 -1.70530257e-13 16"></polygon>
                  </g>
                  <polygon id="Rectangle-Copy-18" fill="#F8F9FB"
                           transform="translate(66.000000, 51.500000) scale(-1, 1) translate(-66.000000, -51.500000) "
                           points="62 45 79 45 70 58 53 58"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="el-empty__description"><p>暂无数据</p></div><!----></div>
    </div>
    <div>
      <add ref="add" @children="parentAdd"></add>
    </div>

    <!-- 添加记录dialog-->
    <el-dialog
        :option="tableOption"
        :data="tableData"
        :title='$t("Add record")'
        width="60%"
        style="marginTop:50px;"
        :append-to-body="true"
        :visible.sync="dialogVisible2"
        :before-close="handleClose">
      <div class="">
        <el-form ref="shiftForm" v-if="currentRow" :model="currentRow" label-width="120px">
          <div class="" style="display:flex;">
            <el-form-item label="设备" style="flex: 1">
              <el-input v-model="currentRow.machineCode" placeholder="设备"></el-input>
            </el-form-item>
            <el-form-item label="开始时间" style="flex: 1;margin-left: 10px;">
              <el-date-picker
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="开始时间"
                  format="yyyy-MM-dd HH:mm:ss"
                  v-model="currentRow.startTime"
                  :picker-options="startPickerOptions"
                  style="width: 100%;"></el-date-picker>
            </el-form-item>
          </div>
          <div class="" style="display:flex;">
            <el-form-item label="班组" style="flex: 1">
              <el-select v-model="currentRow.groupId" @change="getUserName" placeholder="请选择班组"
                         style="width: 100%;">
                <el-option
                    v-for="(item, index) in workShopOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结束时间" style="flex: 1;margin-left: 10px;">
              <el-date-picker type="datetime" disabled value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间"
                              v-model="currentRow.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </div>
          <div class="" style="display:flex;">
            <!--            <el-form-item label="品种名称" style="flex: 1">-->
            <!--              <el-select v-model="currentRow.groupId" @change="getYield" placeholder="请选择品种名称"-->
            <!--                         style="width: 100%;">-->
            <!--                <el-option-->
            <!--                    v-for="(item, index) in productOptions"-->
            <!--                    :key="index"-->
            <!--                    :label="item.name"-->
            <!--                    :value="item.id">-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->


            <el-form-item label="岗位" style="flex: 1;">
              <el-select v-model="currentRow.postId" placeholder="请选择岗位" style="width: 100%;">
                <el-option
                    v-for="(item, index) in postOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item label="开始设备米数" style="flex: 1;margin-left: 10px">
              <!--              <el-input placeholder="开始设备米数" @change="getYield(currentRow)" type="number" :min="currentRow.startIotNumber" :max="currentRow.endIotNumber"-->
              <!--                        v-model="currentRow.startIotNumber" style="width: 100%;"></el-input>-->

              <el-input-number style="width: 100%;" placeholder="开始设备米数" @change="getYield(currentRow)"
                               v-model="currentRow.startIotNumber" controls-position="right"
                               :min="tempNum" :max="currentRow.endIotNumber"></el-input-number>
            </el-form-item>

          </div>
          <div class="" style="display:flex;">
            <el-form-item label="人员名称" style="flex: 1">
              <el-select v-model="currentRow.userId" placeholder="请选择人员" style="width: 100%;">

                <el-option
                    v-for="(item, index) in userOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.userId">
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item label="结束设备米数" style="flex: 1;margin-left: 10px;">
              <el-input placeholder="结束设备米数" disabled @change="getYield(currentRow)" type="number"
                        v-model="currentRow.endIotNumber" style="width: 100%;"></el-input>
            </el-form-item>

          </div>
          <div class="" style="display:flex;">
            <el-form-item label="预估产量" style="flex: 1;">
              <el-input readOnly placeholder="预估产量" v-model="currentRow.yield" style="width: 100%;"></el-input>
            </el-form-item>
          </div>
        </el-form>

        <!--        <el-form ref="shiftForm" :model="shiftForm" label-width="80px" v-if="false">-->
        <!--          <el-form-item>-->
        <!--            <div class="demo-input-suffix">-->
        <!--              <div class="demo-input-suffix">-->
        <!--                <el-col :span="8">-->
        <!--                  <div style="display: flex">-->
        <!--                    <div style="" class="label">设备:</div>-->
        <!--                    <el-input v-model="shiftForm.machineCode" placeholder="设备"></el-input>-->
        <!--                  </div>-->
        <!--                </el-col>-->
        <!--              </div>-->
        <!--              <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>-->
        <!--              <div class="demo-input-suffix">-->
        <!--                <el-col :span="8">-->
        <!--                  <div style="display: flex">-->
        <!--                    <div style="" class="label">开始时间:</div>-->
        <!--                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss"-->
        <!--                                    placeholder="开始时间" v-model="shiftForm.startTime"-->
        <!--                                    style="width: 100%;"></el-date-picker>-->
        <!--                  </div>-->
        <!--                </el-col>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item>-->
        <!--            <div class="demo-input-suffix">-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">班组:</div>-->
        <!--                  &lt;!&ndash;                <el-input v-model="shiftForm.groupName" placeholder="班组"></el-input>&ndash;&gt;-->
        <!--                  <el-select v-model="groupNameValue" @change="getUserName" placeholder="请选择班组">-->
        <!--                    <el-option-->
        <!--                        v-for="(item, index) in workShopOptions"-->
        <!--                        :key="index"-->
        <!--                        :label="item.name"-->
        <!--                        :value="item.id">-->
        <!--                    </el-option>-->
        <!--                  </el-select>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--              <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">结束时间:</div>-->
        <!--                  <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime"-->
        <!--                                  placeholder="结束时间" v-model="shiftForm.endTime"-->
        <!--                                  style="width: 100%;"></el-date-picker>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--            </div>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item>-->
        <!--            <div class="demo-input-suffix">-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">品种名称:</div>-->
        <!--                  &lt;!&ndash;                <el-input v-model="shiftForm.shiftName" placeholder="班制"></el-input>&ndash;&gt;-->
        <!--                  &lt;!&ndash;                  <el-select v-model="shiftNameValue" placeholder="请选择班制">&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    <el-option&ndash;&gt;-->
        <!--                  &lt;!&ndash;                            v-for="(item, index) in shiftOptions"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                            :key="index"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                            :label="item.name"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                            :value="item.id">&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    </el-option>&ndash;&gt;-->
        <!--                  &lt;!&ndash;                  </el-select>&ndash;&gt;-->
        <!--                  <el-select v-model="productValue" @change="getYield" placeholder="请选择品种名称">-->
        <!--                    <el-option-->
        <!--                        v-for="(item, index) in productOptions"-->
        <!--                        :key="index"-->
        <!--                        :label="item.name"-->
        <!--                        :value="item.id">-->
        <!--                    </el-option>-->
        <!--                  </el-select>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--              <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">开始设备米数:</div>-->
        <!--                  <el-input placeholder="开始设备米数" v-model="shiftForm.startIotNumber"-->
        <!--                            style="width: 100%;"></el-input>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--            </div>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item>-->
        <!--            <div class="demo-input-suffix">-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">人员名称:</div>-->
        <!--                  &lt;!&ndash;                <el-input v-model="shiftForm.userName" placeholder="人员名称"></el-input>&ndash;&gt;-->
        <!--                  <el-select v-model="userNameValue" placeholder="请选择人员">-->
        <!--                    <el-option-->
        <!--                        v-for="(item, index) in userOptions"-->
        <!--                        :key="index"-->
        <!--                        :label="item.name"-->
        <!--                        :value="item.userId">-->
        <!--                    </el-option>-->
        <!--                  </el-select>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--              <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">结束设备米数:</div>-->
        <!--                  <el-input placeholder="结束设备米数" v-model="shiftForm.endIotNumber"-->
        <!--                            style="width: 100%;"></el-input>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--            </div>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item>-->
        <!--            <div class="demo-input-suffix">-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">岗位:</div>-->
        <!--                  &lt;!&ndash;                <el-input v-model="shiftForm.postName" placeholder="岗位"></el-input>&ndash;&gt;-->
        <!--                  <el-select v-model="postValue" placeholder="请选择岗位">-->
        <!--                    <el-option-->
        <!--                        v-for="(item, index) in postOptions"-->
        <!--                        :key="index"-->
        <!--                        :label="item.name"-->
        <!--                        :value="item.id">-->
        <!--                    </el-option>-->
        <!--                  </el-select>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--              <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>-->
        <!--              <el-col :span="8">-->
        <!--                <div style="display: flex">-->
        <!--                  <div style="" class="label">预估产量:</div>-->
        <!--                  <el-input placeholder="预估产量" v-model="shiftForm.yield"-->
        <!--                            style="width: 100%;"></el-input>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--            </div>-->
        <!--          </el-form-item>-->
        <!--          &lt;!&ndash;          <el-form-item>&ndash;&gt;-->
        <!--          &lt;!&ndash;            <div class="demo-input-suffix">&ndash;&gt;-->
        <!--          &lt;!&ndash;              <el-col :span="8">&ndash;&gt;-->
        <!--          &lt;!&ndash;                <div style="display: flex">&ndash;&gt;-->
        <!--          &lt;!&ndash;                  <div style="" class="label">品种名称:</div>&ndash;&gt;-->
        <!--          &lt;!&ndash;                  &lt;!&ndash;                <el-input v-model="shiftForm.postName" placeholder="岗位"></el-input>&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;                  <el-select v-model="productValue" @change="getYield" placeholder="请选择品种名称">&ndash;&gt;-->
        <!--          &lt;!&ndash;                    <el-option&ndash;&gt;-->
        <!--          &lt;!&ndash;                            v-for="(item, index) in productOptions"&ndash;&gt;-->
        <!--          &lt;!&ndash;                            :key="index"&ndash;&gt;-->
        <!--          &lt;!&ndash;                            :label="item.name"&ndash;&gt;-->
        <!--          &lt;!&ndash;                            :value="item.id">&ndash;&gt;-->
        <!--          &lt;!&ndash;                    </el-option>&ndash;&gt;-->
        <!--          &lt;!&ndash;                  </el-select>&ndash;&gt;-->
        <!--          &lt;!&ndash;                </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;              </el-col>&ndash;&gt;-->
        <!--          &lt;!&ndash;              &lt;!&ndash;              <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;              &lt;!&ndash;              <el-col :span="8">&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;              &lt;!&ndash;                <div style="display: flex">&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;              &lt;!&ndash;                  <div style="" class="label">预估产量:</div>&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;              &lt;!&ndash;                  <el-input  placeholder="预估产量" v-model="shiftForm.yield" style="width: 100%;"></el-input>&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;              &lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;              &lt;!&ndash;              </el-col>&ndash;&gt;&ndash;&gt;-->
        <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </el-form-item>&ndash;&gt;-->
        <!--        </el-form>-->

      </div>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2 = false">取 消</el-button>
    <el-button type="primary" @click="insertBtn">确 定</el-button>
  </span>
    </el-dialog>
    <!--    <template>
          <div>
            <basic-container>
              <avue-crud
                         :option="tableOption"
                         :data="tableData"
                         :title='$t("Add record")'
                         width="80%"
                         style="marginTop:50px;"
                         :append-to-body="true"
                         :visible.sync="dialogVisible2"
                         :before-close="handleClose"
                         >

                <template slot="menu" slot-scope="{row,index,size,type}">
                  <el-button icon="el-icon-data-analysis" :size="size" :type="type" @click="goReport(row)">{{$t("Check the report")}}
                  </el-button>
                </template>
                <template slot-scope="{row,index,type}" slot="menuForm">
                  <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'"
                             @click="$refs.crud.rowSave()">{{$t("Hold")}}
                  </el-button>
                  <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'"
                             @click="$refs.crud.rowUpdate()">{{$t("Hold")}}
                  </el-button>
                  <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">
                    {{$t("Cancel")}}
              </el-button>
            </template>
          </avue-crud>
        </basic-container>
      </div>
    </template>-->
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import {fullscreenToggel, listenfullscreen} from "@/util/util";
import draggable from "vuedraggable";

import {
  getNameByLike,
  selectList,
  batchForCheck,
  getpepoelChange,
  getClassAndPepole,
  batchForDuty,
  jobReplaceDetails,
  selectWorkShopName,
  selectAllByUserChange,
  timeYield,
  getUser,
  processSelectByWorkShopId
} from "@/api/pepoleChange";
import {dateFormat} from "@/util/date";
import {getGrop} from "@/util/auth";
import {getGroupUser} from "@/api/config";

export default {
  data() {
    return {
      isRemove: false,
      tempRow: null,
      tempList: [],
      currentDetails: null,
      machine_id: '',
      tempStrTime: '',
      tempNum: '',
      currentRow: null,
      activeNames: ['1', '2', '3'],
      processIData: [],
      originalTableData: [],
      timeYieldData1: [],
      // replaceData: [],//替岗人产量数据
      // byReplacedData: [],//被替岗人产量数据
      shiftAndGroup: [],
      totalYield: 0,
      options: [],
      shiftForm: [],
      value: '',
      originYield: '',
      groupNameValue: '',
      shiftNameValue: '',
      userNameValue: '',
      postValue: '',
      productValue: '',
      shiftOptions: [],
      workShopOptions: [],
      postOptions: [],
      userOptions: [],
      productOptions: [],
      scH: window.screen.height,
      isTop: false,
      isPre: false,
      isNex: true,
      currentId: 0,
      ishasSeletNum: 0,
      machineId: '',
      shiftId: '',
      checkboxList: [],
      scHei: {
        height: "",
        top: 0,
      },
      outHei: {
        height: "",
        top: 0,
      },
      itemHeight: {
        height: "",
        background: "#fff",
        "box-shadow": "0px 4px 12px 0px rgba(11, 51, 117, 0.1)",
        margin: "0 7.5px 15px",
        width: "calc(25% - 15px)",
        "box-sizing": "border-box",
        "border-radius": "8px",
        display: "flex",
        "flex-wrap": "wrap",
      },
      checkboxYes: [],
      checkboxAll: [],
      codesTitle: "",
      isIndeterminate: false,
      innerVisible: false,
      indexCod: "",
      form: {},
      tableData: [],
      tableOption: {
        delBtn: true,
        menuWidth: 200,
        refreshBtn: false,
        selection: true,
        tip: false,
        columnBtn: false,
        dialogFullscreen: true,
        addBtnText: this.$t('ADD'),
        delBtnText: this.$t('DELETE'),
        editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('Add'),
        saveBtn: false,
        updateBtn: false,
        cancelBtn: false,
        column: [
          {
            label: this.$t('USER CODE'),
            prop: "code",
            width: 80,
            placeholder: this.$t('PLEASE ENTER THE USER CODE'),
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER THE USER CODE'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('USER NAME'),
            prop: "name",
            width: 100,
            placeholder: this.$t('PLEASE ENTER A USER NAME'),
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER A USER NAME'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('TELEPHONE'),
            prop: "phoneNumber",
            width: 110,
            placeholder: this.$t('PLEASE ENTER PHONE NUMBER WARN'),
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER PHONE NUMBER'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('GENDER'),
            type: "select",
            prop: "gender",
            placeholder: this.$t('PLEASE SELECT GENDER'),
            width: 100,
            dicData: [{
              label: this.$t('MALE'),
              value: 1
            }, {
              label: this.$t('FEMALE'),
              value: 2
            }, {
              label: this.$t('UNKNOWN'),
              value: 0
            }, {
              label: this.$t('OTHER'),
              value: 3
            }, {
              label: this.$t('未说明'),
              value: 9
            }],
            rules: [{
              required: true,
              message: this.$t('PLEASE SELECT GENDER'),
              trigger: "blur"
            }]
          }, {
            label: this.$t('EMAIL'),
            prop: "email",
            width: 200,
            placeholder: this.$t('PLEASE ENTER EMAIL ADDRESS'),
            /*            rules: [{
                          required: true,
                          message: this.$t('PLEASE ENTER EMAIL ADDRESS'),
                          trigger: "blur"
                        }]*/
          },
          {
            label: this.$t('BELONGING DEPARTMENT'),
            placeholder: this.$t('PLEASE SELECT THE DEPARTMENT'),
            //hide: true,
            dicData: [],
            props: {
              label: "name",
              value: "id"
            },
            type: "select",
            prop: "departmentId",
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER YOUR DEPARTMENT'),
              trigger: "blur"
            }],
            formatter: function (row) {
              let name = row.departmentName;
              return name;
            }
          },
          {
            label: this.$t('JOB'),
            placeholder: this.$t('PLEASE SELECT A POSITION'),
            //hide: true,
            dicData: [],
            props: {
              label: "name",
              value: "id"
            },
            rules: [{
              required: true,
              message: "请选择",
              trigger: ""
            }],
          },
          {
            label: this.$t('STATUS'),
            //value: 0,
            type: "select",
            addDisplay: false,
            dicData: [
              {
                label: this.$t('QUIT'),
                value: 1
              },
              {
                label: this.$t('TO BE CONFIRMED'),
                value: -1
              },
              {
                label: this.$t('ON THE JOB'),
                value: 0
              }, {
                label: this.$t('REFUSE'),
                value: -9
              }
            ],
            prop: "status",
          }]
      },
      goForm: {class: "", res: "", change: ""},
      //批量替岗
      dialogVisible: false,
      dialogVisible2: false,
      changeValue: {班次: "", 班组: "", 员工: ""},
      //机器人员数据
      changeData: {},
      formInline: {
        time: dateFormat(new Date()),
        time2: dateFormat(new Date()),
        userName: "",
        workShopId: "",
        machineCode: "",
        processId: '',
        suShiftId: ''
      },
      list: [],
      workCenter: [],
      title: "",
      forPost: "",
      forPostItem: [],
      paramsList: {},
      selectItem: {},
      forPostId: "",
      nameList: [],
      nameRadio: "",
      innerSave: false,
      codeList: [],
      showAddUser: false,
      newUserForm: {
        gender: 1,
        name: "",
        phoneNumber: "",
        status: 0,
      },
      // forPostObj:{}
      processId: ''
    }
  },
  computed: {
    startPickerOptions() {
      console.log(this.currentRow.startTime)
      let minDate = this.tempStrTime.split(' ')[1]
      let maxDate = this.currentRow.endTime.split(' ')[1]
      return {
        // disabledDate: (time) => {
        //   return false
        //   //return (time.getTime() >= new Date(this.currentRow.startTime).getTime() || time.getTime() <= new Date(this.currentRow.endTime).getTime())
        // },
        // selectableRange: minDate + " - " + maxDate
      }
    },
    setData() {
      return {
        allText: "全选",
        isCheck: true,
        topIsMenu: true,
        topIsCheMenu: false,
        changeData2: {},
      }
    },
    ...mapState({
      showDebug: (state) => state.common.showDebug,
      showColor: (state) => state.common.showColor,
      showTheme: (state) => state.common.showTheme,
      showLock: (state) => state.common.showLock,
      showFullScren: (state) => state.common.showFullScren,
      showCollapse: (state) => state.common.showCollapse,
      showSearch: (state) => state.common.showSearch,
      showMenu: (state) => state.common.showMenu,
    }),
    ...mapGetters([
      "userInfo",
      "isFullScren",
      "tagWel",
      "tagList",
      "isCollapse",
      "tag",
      "logsLen",
      "logsFlag",
    ]),
  },
  created() {
    getUser().then(res => {
      console.log(res);
      this.option.column[0].dicData = res.data.data;
    });
    this.getCenter();
    //this.sortChange();
    // this.processSelectByWorkShopIdList('1df5223cf1694973be47bd4223c9349d')
  },
  components: {draggable},
  mounted() {
    listenfullscreen(this.getHeight);
    this.getHeight();
  },
  methods: {
    // 设置开始日期
    pickerOptionStart() {
    },
    handleCurrentChange(val) {
      this.currentRow = JSON.parse(JSON.stringify(val));
    },
    async getYield(currentRow) {

      let {machineCode, startTime, endTime, startIotNumber, endIotNumber} = currentRow
      let params = {
        machineId: this.machine_id,
        strTime: startTime,
        endTime: endTime,
        timeStrIotNumber: startIotNumber,
        timeEndIotNumber: endIotNumber
      }
      console.log(params)

      const res = await this.request.post(this.proApis.CALCULATEPRODUCTIONYIELD, params)
      if (res.data.code == '0000') {
        this.currentRow.yield = res.data.data
      } else {
      }

    },
    async getUserName() {
      this.queryUser();
    },

    addRecord() {
      /*     if (this.timeYieldData1.length > 0) {
             this.groupNameValue = this.workShopOptions[0].id;
             this.userNameValue = this.userOptions[0].userId;
             this.postValue = this.postOptions[0].id;
             this.productValue = this.productOptions[0].id;
             // console.log(this.userOptions, '-')
             if (this.userOptions) {
               this.userOptions.forEach((f) => {
                 if (f.name == this.timeYieldData1[0].userName) {
                   this.userNameValue = f.userId
                 }
               })
             }
           }*/

      if (this.timeYieldData1.length == 0) {
        this.groupNameValue = '';
        this.postValue = '';
        this.productValue = '';
        this.userNameValue = '';
      } else {
        this.groupNameValue = this.timeYieldData1[0].groupId;
        this.userNameValue = this.timeYieldData1[0].userId;
        this.postValue = this.timeYieldData1[0].postId;
        this.productValue = '';
      }
      // this.shiftForm.machineCode = '';
      // this.shiftForm.startTime = '';
      // this.shiftForm.endTime = '';
      // this.shiftForm.startIotNumber = '';
      // this.shiftForm.endIotNumber = '';
      // this.shiftForm.yield = '';

      //this.currentRow = this.currentRow ? this.currentRow : JSON.parse(JSON.stringify(this.timeYieldData1[this.timeYieldData1.length - 1]));
      this.tempRow = this.tempList.length > 0 && JSON.parse(JSON.stringify(this.tempList[this.tempList.length - 1])) || JSON.parse(JSON.stringify(this.timeYieldData1[this.timeYieldData1.length - 1]));

      this.currentRow = this.tempList.length > 0 && JSON.parse(JSON.stringify(this.tempList[this.tempList.length - 1])) || JSON.parse(JSON.stringify(this.timeYieldData1[this.timeYieldData1.length - 1]));

      console.log('currentRow currentRow currentRow ')
      console.log(this.currentRow)


      this.currentRow.endTime = this.originalTableData[this.originalTableData.length - 1]['endTime']
      this.currentRow.endIotNumber = this.originalTableData[this.originalTableData.length - 1]['endIotNumber']
      this.currentRow.yield = ''

      this.tempNum = this.currentRow.startIotNumber;
      this.tempStrTime = this.currentRow.startTime;
      this.tempEndTime = this.currentRow.endTime;

      console.log(this.currentRow)
      this.dialogVisible2 = true;
    },
    handleClose() {
      this.dialogVisible2 = false;
      //this.tempList = this.$options.data.call(this).tempList
      this.currentRow = this.$options.data.call(this).currentRow
      this.$refs.singleTable.setCurrentRow();
    },
    //移除记录
    removeRecord() {
      this.isRemove = true;
      //this.timeYieldData1.pop()

      let curDelRow = this.timeYieldData1[this.timeYieldData1.length - 1];

      let lastIndex = this.tempList.findIndex((item) => {
        return item == curDelRow
      })

      if (this.tempList.length <= 2) {
        this.tempList = [];
        this.timeYieldData1 = [...this.originalTableData]
      } else {
        console.log('lastIndex', lastIndex)
        this.tempList.splice(lastIndex - 1, 2)
        let lastRow = this.tempList[this.tempList.length - 1];

        let arr1 = this.tempList.filter((v) => {
          if (v.endTime != this.tempEndTime) {
            return v
          }
        })
        this.timeYieldData1 = [...this.originalTableData, ...arr1, lastRow]
        console.log('remove-list ')
        console.log(this.timeYieldData1)
        console.log(this.tempList)
      }

    },

    // sub table line
    async insertBtn() {
      console.log('sub')
      console.log('current---')
      console.log(this.currentRow)
      console.log('tempRow---')
      console.log(this.tempRow)


      if (!this.currentRow.yield) {
        this.$message.error('Yield is Required')
        return
      }

      if (this.currentRow.startTime <= this.tempRow.startTime || this.currentRow.startTime > this.currentRow.endTime) {
        this.$message.error('Time Selection Error')
        return
      }


      // current params
      this.currentRow.userName = this.currentRow.userId && this.userOptions.find((v) => {
        return v.userId == this.currentRow.userId
      })['name']
      this.currentRow.groupName = this.currentRow.groupId && this.workShopOptions.find((v) => {
        return v.id == this.currentRow.groupId
      })['name']
      this.currentRow.postName = this.currentRow.postId && this.postOptions.find((v) => {
        return v.id == this.currentRow.postId
      })['name']


      //temp params
      this.tempRow.endIotNumber = this.currentRow.startIotNumber
      this.tempRow.endTime = this.currentRow.startTime
      this.tempRow.isShow = true
      //this.tempRow.yield = (this.currentRow.yield - this.tempRow.yield) < 0 ? 0 : this.currentRow.yield - this.tempRow.yield
      this.tempRow.yield = (this.currentRow.yield - this.tempRow.yield)

      if (this.isRemove) {

      }

      this.tempList.push(this.tempRow)
      this.tempList.push(this.currentRow)


      let lastItem = this.currentRow;

      console.log(lastItem)

      let arr1 = this.tempList.filter((item) => {
        if (item.endTime != this.currentRow.endTime && item != this.currentRow) {
          return item
        }
      })

      console.log(arr1)
      this.timeYieldData1 = [...this.originalTableData, ...arr1, lastItem]

      this.dialogVisible2 = false
      this.currentRow = this.$options.data.call(this).currentRow
      this.tempRow = this.$options.data.call(this).tempRow
      this.$refs.singleTable.setCurrentRow();


      return
      let params = {
        corpUsersId: this.paramsList.员工,
        suShiftId: this.shiftAndGroup[0],
        suGroupId: this.shiftAndGroup[1],
        postId: this.forPostId,
        changeUserId: this.form.changeUserId,
        //changeStartDate: this.formInline.time2 + " " + this.form.changeStartDate + ":00",
        //changeEndDate: this.formInline.time2 + " " + this.form.changeEndDate + ":00",
        codes: [this.indexCod],
        time: this.formInline.time2
      };
      let paramsChange = {
        corpUsersId: this.paramsList.员工,
        suShiftId: this.shiftNameValue,
        suGroupId: this.groupNameValue,
        postId: this.postValue,
        changeUserId: this.userNameValue,
        createUserId: this.userNameValue,
        changeStartDate: this.shiftForm.startTime,
        changeEndDate: this.shiftForm.endTime,
        codes: [this.indexCod],
        time: this.formInline.time2,
        startIotNumber: this.shiftForm.startIotNumber,
        endIotNumber: this.shiftForm.endIotNumber,
        yield: this.shiftForm.yield,
        originYield: this.originYield
      };
      console.log(params);
      console.log(paramsChange);
      console.log(this.shiftForm.startTime)
      // return
      // paramsChange.changeStartDate = new Date(paramsChange.changeStartDate);
      // paramsChange.changeEndDate = new Date(paramsChange.changeEndDate);
      const res = await jobReplaceDetails(paramsChange);
      if (res.data.code == "0000") {
        this.$message({
          type: "success",
          message: "添加成功",
        });
      }
      console.log(res, "ijn");
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
    parentAdd() {

    },
    workChange(value) {
      this.processSelectByWorkShopIdList(value)
    },
    //根据车间查询工序
    processSelectByWorkShopIdList(value) {
      processSelectByWorkShopId(value).then((respoinse) => {
        if (respoinse.data.data.length > 0) {
          this.processIData = respoinse.data.data;
          this.formInline.processId = this.processIData[0].id;
          this.processId = this.processIData[0].id;
          this.queryShiftOptions(this.processId);
          this.onSearch();
        } else {
          this.processIData = [];
          this.processId = '';
          this.formInline.processId = '';
          this.shiftOptions = [];
          this.shiftNameValue = '';
        }
      })
    },
    arraySpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 4) {
        if (rowIndex === 0) {
          return {
            rowspan: this.timeYieldData.length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      // if (row.flag==1){
      //   if (columnIndex === 4){
      //     return [1, 2];
      //     //return [this.countByReplacedData,0];
      //   }else if (columnIndex === 5){
      //     return [0,0];
      //   }
      //
      // }
    },
    yieldFormatter(row) {
      return row.yield + " kg"
    },
    byReplacedFormatter(row) {
      return row.byReplacedData + " kg"
    },
    replaceDataFormatter(row) {
      return row.replaceData + " kg"
    },
    getSummaries(param) {
      let sumYield = 0;
      let sumReplaceData = 0;
      let sumByReplacedData = 0;
      this.timeYieldData.forEach(item => {
        sumYield += item.yield * 100000;
        sumReplaceData += item.replaceData * 100000;
        sumByReplacedData += item.byReplacedData * 100000;
      })
      const {columns, data} = param;
      const sums = [];
      sums[0] = '合计';
      sums[1] = null;
      sums[2] = sumYield / 100000 + " kg";
      sums[3] = sumByReplacedData / 100000 + " kg";
      sums[4] = null;
      sums[5] = sumReplaceData / 100000 + " kg";


      return sums;
    },
    replaceYield() {
      this.timeYieldData.forEach(item => {
        if (item.flag) {
          let tmp = item.replaceData;
          item.replaceData = item.byReplacedData;
          item.byReplacedData = tmp;
        }
      })

    },
    timeYieldChange() {

      // if (this.paramsList.员工 && this.form.changeStartDate && this.form.changeEndDate) {
      if (this.form.changeStartDate && this.form.changeEndDate) {
        timeYield(
            this.formInline.time2,
            this.indexCod,
            this.shiftAndGroup[0],
            this.forPostId,
            this.paramsList.员工,
            this.shiftAndGroup[1],
            this.formInline.time2 + ' ' + this.form.changeStartDate + ':00',
            this.formInline.time2 + ' ' + this.form.changeEndDate + ':00',
            this.formInline.time2,
        ).then(res => {
          console.log("qqqqqqqqqqqq", res)
          this.timeYieldData = [];
          this.totalYield = res.data.data.totalYield
          res.data.data.listData.forEach(item => {
            if (item.timeInterval != null && item.yield != null && item.energy != null) {
              this.$set(item, 'replaceData', 0);
              this.$set(item, 'byReplacedData', item.yield);
              this.timeYieldData.push(item);
            }
          })
          this.$forceUpdate();
        })
      }
    },
    async shiftAndGroupChange() {
      let res = await getClassAndPepole(
          this.shiftAndGroup[0],
          this.indexCod,
          null,
          this.formInline.time2,
          this.shiftAndGroup[1],
          this.formInline.workShopId
      );
      this.paramsList.班次 = this.shiftAndGroup[0];
      this.changeValue.班次 = this.shiftAndGroup[0];
      this.paramsList.班组 = this.shiftAndGroup[1];
      this.changeValue.班组 = this.shiftAndGroup[1];
      this.forPostItem = JSON.parse(JSON.stringify(res.data.data.工种));
      this.setData.changeData2 = res.data.data;
      this.forPost = "";
      this.forPostId = "";
      this.$forceUpdate();
    },
    nextList() {
      let setHei = Number(this.outHei.height.split("px")[0]);
      let othHei = -setHei;
      if (this.currentId >= parseInt(this.list.length / 12)) {
        this.$message({
          type: "warning",
          message: "已经是最后一页啦~",
        });
        this.currentId = parseInt(this.list.length / 12);
      } else {
        this.currentId++;
        this.isTop = true;
        this.isPre = true;
        this.scHei.top = this.currentId * othHei + 7.5 + "px";
      }
      console.log(this.currentId);
    },
    preList() {
      let setHei = Number(this.outHei.height.split("px")[0]);
      let othHei = -setHei;
      if (this.currentId <= 1) {
        this.isTop = false;
        this.isPre = false;
        this.$message({
          type: "warning",
          message: "已经是第一页啦~",
        });
        this.scHei.top = 0;
        this.currentId = 0;
      } else {
        this.currentId--;
        this.isTop = true;
        this.isPre = true;
        this.scHei.top = this.currentId * othHei + 7.5 + "px";
      }
      console.log(this.currentId);
    },
    toTop() {
      this.isTop = false;
      this.isPre = false;
      this.$message({
        type: "success",
        message: "已经到第一页啦~",
      });
      this.scHei.top = 0;
      this.currentId = 0;
    },
    // moreFrom() {
    //   let fromHei = document.querySelector(".formList").clientHeight;
    //   if (fromHei > 40) {
    //     document.querySelector(".formList").style.cssText =
    //       "width: calc(100% - 240px); height: 40px; overflow: hidden;";
    //     document.querySelector(".outFormDiv").style.cssText =
    //       "display: flex; justify-content: space-between; padding: 15px; background: #F2F2F2; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;";
    //   } else {
    //     document.querySelector(".formList").style.cssText =
    //       "width: calc(100% - 240px);height:auto;";
    //     document.querySelector(".outFormDiv").style.cssText =
    //       "display: flex; justify-content: space-between; padding: 15px; background: #F2F2F2; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;box-shadow:0px 15px 10px 0px rgba(0,0,0,.1);";
    //   }
    // },
    getHeight(h) {
      //let hei;
      //let scH = window.screen.height;
      let docH = document.getElementById("leftHeight").clientHeight;
      console.log(this.scH, docH);
      if (this.isFullScren) {
        let hei = this.scH - 70;
        this.bigHeight = hei;
        this.itemHeight.height = hei / 3 - 17.5 + "px";
        this.outHei.height = hei + "px";
        this.outHei.top = "70px";
      } else {
        let hei = docH - 70;
        this.bigHeight = hei;
        this.itemHeight.height = hei / 3 - 27.5 + "px";
        this.outHei.height = hei - 27.5 + "px";
        this.outHei.top = "0";
      }
    },
    daochu() {
      window.location.href =
          this.proApis.SCHEDULEUSEREXPORT + "?corpId=" + getGrop();
    },
    // 新增员工
    async addUser() {
      const res = await this.request.post(
          this.proApis.CORPUSERSINSERT,
          this.newUserForm
      );
      console.log(res, "ress");
      if (res.data.code === "0000") {
        this.$message.success("添加成功");
        this.form.changeUserName = this.newUserForm.name;
        this.showAddUser = false;
      }
    },
    async searchName() {
      if (!this.form.changeUserName)
        return this.$message.warning("请输入搜索内容");
      console.log(this.form.changeUserName, "this.form.changeUserName");
      const res = await getNameByLike(this.form.changeUserName);
      if (res.data.data.length === 0) {
        await this.$confirm("未查到用户,是否新增?", "提示");
        this.newUserForm.name = this.form.changeUserName;
        this.showAddUser = true;
      } else {
        this.nameList = res.data.data;
        this.innerVisible = true;
        console.log(res);
      }
    },
    selectName() {
      console.log(this.nameRadio);
      this.form.changeUserName = this.nameRadio;
      this.innerVisible = false;
    },
    async dateChange(val) {
      if (!val) {
        return ''
      }
      let res;
      if (this.title == "替岗") {
        res = await getpepoelChange(this.indexCod, "", this.formInline.time2, this.formInline.workShopId);
      } else {
        res = await selectAllByUserChange({
          codes: this.checkboxYes,
          postId: "",
          time: this.formInline.time2,
        });
      }
      this.options = [];
      this.shiftAndGroup = [];
      res.data.data.班次.forEach(item => {
        this.options.push({
          value: item.shiftId,
          label: item.shiftName,
          children: [{
            value: item.groupId,
            label: item.groupName
          }]
        })
      })
      console.log(res);
      this.$nextTick(() => {
        this.setData.changeData2 = JSON.parse(JSON.stringify(res.data.data));
        this.forPostItem = JSON.parse(JSON.stringify(res.data.data.工种));
      });
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
      this.forPostId = "";
    },
    // 点击工种打开替岗
    async openForPost(indexCod, id, value, item) {
      //alert(1)
      this.currentDetails = item;
      this.tempList = [];
      console.log("1", indexCod);
      console.log("2", id);
      console.log("3", value);
      console.log("4", item);
      this.machineId = item.machineId;
      this.shiftId = item.shiftId;
      this.machine_id = id;
      this.sortChange();
      this.queryWorkShop();
      this.queryPost();
      this.queryUser();
      let productObj = this.request({
        url: this.businessUrl + "product/selectAll",
        method: "get",
        data: {}
      });
      productObj.then(res => {
        res.data.data = res.data.data.filter(v => {
          return v.enableBatch == 1;
        });
        this.productOptions = res.data.data;
      });
      // await this.getUser(indexCod,'')
      this.codesTitle = indexCod;
      this.indexCod = indexCod;
      //this.forPostItem = item;
      // this.setData.changeData2 = res
      this.forPost = value[0].postName;
      this.forPostId = value[0].postId;
      this.formInline.time2 = this.formInline.time;
      const res = await getpepoelChange(
          indexCod,
          null,
          this.formInline.time,
          this.formInline.workShopId
      );
      if (res.data.code != "0000") {
        return;
      }
      const resPost = await getClassAndPepole(
          item.shiftId,
          indexCod,
          null,
          this.formInline.time,
          item.groupId,
          this.formInline.workShopId
      );
      if (resPost.data.code != "0000") {
        return;
      }
      const resUser = await getClassAndPepole(
          item.shiftId,
          indexCod,
          value[0].postId,
          this.formInline.time,
          item.groupId,
          this.formInline.workShopId
      );
      if (resUser.data.code != "0000") {
        return;
      }
      this.$nextTick(() => {
        console.log(res, "res");
        this.options = [];
        res.data.data.班次.forEach(item => {
          this.options.push({
            value: item.shiftId,
            label: item.shiftName,
            children: [{
              value: item.groupId,
              label: item.groupName
            }]
          })
        })
        this.shiftAndGroup[0] = item.shiftId;
        this.shiftAndGroup[1] = item.groupId;
        this.setData.changeData2.班次 = JSON.parse(JSON.stringify(res.data.data.班次));
        this.forPostItem = JSON.parse(JSON.stringify(resPost.data.data.工种));
        this.setData.changeData2.员工 = JSON.parse(JSON.stringify(resUser.data.data.员工));
      });
      this.changeValue.员工 = "";
      this.timeYieldData = [];
      this.form.changeStartDate = "";
      this.form.changeEndDate = "";
      console.log(this.setData.changeData2, "data");
      this.dialogVisible = true;
      this.title = "替岗";
    },
    // 点击工种
    async clickGZ(value3) {
      console.log(value3);
      this.forPostId = value3.id;
      let codes = this.title == "替岗" ? this.indexCod : this.checkboxYes;
      let res;
      if (this.title == "替岗") {
        res = await getClassAndPepole(this.shiftAndGroup[0], codes, value3.id, this.formInline.time2, this.shiftAndGroup[1], this.formInline.workShopId);
        console.log(codes, value3.postId, this.formInline.time2);
      } else {
        res = await selectAllByUserChange({
          codes,
          postId: value3.postId,
          time: this.formInline.time,
        });
        console.log(2);
      }

      console.log(res, "res");
      this.$nextTick(() => {
        this.setData.changeData2.员工 = JSON.parse(JSON.stringify(res.data.data.员工));
        console.log('this.setData.changeData2', this.setData.changeData2);
        this.$forceUpdate();
      });
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
    },
    /*    // 点击班次
        async clickBC(value1) {
          this.paramsList.班次 = value1.id;
          this.changeValue.班次 = String(value1.id);
          let res;
          if (this.title == "替岗") {
            res = await getClassAndPepole(
              value1.id,
              this.indexCod,
              this.forPostId,
              this.formInline.time
            );
          } else {
            res = await selectAllByUserChange({
              suShiftId: value1.id,
              codes: this.checkboxYes,
              postId: this.forPostId,
              time: this.formInline.time,
            });
          }
          console.log(res, "res");
          this.setData.changeData2.班组 = res.data.data.班组;
          this.setData.changeData2.员工 = res.data.data.员工;
        },*/
    // 点击班组
    /*    async groupChage(value2) {
          console.log(value2);
          this.paramsList.班组 = value2.id;
          // this.changeValue.班组 = value2.name
          this.changeValue.班组 = value2.id;
          let res;
          if (this.title == "替岗") {
            res = await getClassAndPepole(
              this.paramsList.班次,
              this.indexCod,
              this.forPostId,
              this.formInline.time,
              value2.id
            );
          } else {
            res = await selectAllByUserChange({
              suShiftId: this.paramsList.班次,
              codes: this.checkboxYes,
              postId: this.forPostId,
              time: this.formInline.time,
              suGroupId: value2.id,
            });
          }
          console.log(res, "res");
          this.setData.changeData2.员工 = res.data.data.员工;
        },*/
    // 点击员工
    personnelChange(val) {
      console.log(this.changeValue.员工, val);
      this.paramsList.员工 = val.userId;
      this.changeValue.员工 = val.name;
      this.timeYieldChange();
    },
    submit() {
    },
    isIndeterminateChange(value) {
      this.checkboxYes.length > 0 &&
      this.checkboxYes.length < this.checkboxAll.length
          ? (this.isIndeterminate = true)
          : (this.isIndeterminate = false);
      //this.checkboxYes.length > 0 && this.checkboxYes.length < this.checkboxAll.length ? this.isIndeterminate = true : this.isIndeterminate = false;
    },
    onSearch() {
      this.form = {};
      this.setData.changeData2 = {};
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
      this.checkboxYes = [];
      //this.formInline.processId = this.processId
      this.formInline.suShiftId = this.shiftNameValue
      console.log("000000000000", this.formInline)
      selectList(this.formInline).then((res) => {
        if (res.data.data) {
          this.list = res.data.data;
          console.log(res.data.data);
          if (this.shiftNameValue == null || this.shiftNameValue == '') {
            this.shiftNameValue = res.data.data[0].shiftId;
          }
          this.checkboxAll = [];
          for (let key in res.data.data) {
            this.checkboxAll.push(key);
          }
        } else {
          this.list = [];
        }
      });
    },
    //退出全选
    outAll(val) {
      this.setData.isCheck = true;
      this.setData.topIsMenu = true;
      this.setData.topIsCheMenu = false;
      this.checkboxYes = [];
      this.setData.allText = "全选";
    },
    //进入全选
    getAll(val) {
      console.log(this.setData.isCheck);
      this.setData.isCheck = false;
      this.setData.topIsMenu = false;
      this.setData.topIsCheMenu = true;
      this.checkboxYes = [];
    },
    onAll(value) {
      value == false
          ? (this.checkboxYes = [])
          : (this.checkboxYes = this.checkboxAll);
      value == false
          ? (this.setData.allText = "全选")
          : (this.setData.allText = "取消");
    },

    //confirm
    async confirm() {
      console.log('confirm')
      console.log(this.timeYieldData1)

      let originalData = this.originalTableData;
      let DataList = this.timeYieldData1.slice(this.originalTableData.length);

      console.log('DataList')
      console.log(DataList)

      let arr2 = originalData.map((item) => {
        return {
          ...item,
          suShiftId: item.shiftId,
          suGroupId: item.groupId,
          originYield: item.yield,
          changeUserId: item.userId,
          changeStartDate: item.startTime,
          changeEndDate: item.endTime,
          workShopId: this.formInline.workShopId,
          processId: this.formInline.processId,
          machineId: this.machine_id
        }
      })

      let arr1 = DataList.map((item) => {
        return {
          ...item,
          suShiftId: item.shiftId,
          suGroupId: item.groupId,
          changeStartDate: item.startTime,
          changeEndDate: item.endTime,
          changeUserId: item.userId,
          workShopId: this.formInline.workShopId,
          processId: this.formInline.processId,
          machineId: this.machine_id
        }
      })

      let params = {
        oldUserChangeRequest: arr2[this.originalTableData.length - 1],
        list: arr1
      }
      console.log(params)
      const res = await jobReplaceDetails(params);
      if (res.data.code == "0000") {
        this.$message.success("替岗成功");
        this.dialogVisible = false;
      }
      return
      let arr = Object.keys(this.list)[this.selectItem];
      if (this.title == "替岗") {
        /*        if (
                    !this.paramsList.员工 ||
                    !this.shiftAndGroup[0] ||
                    !this.shiftAndGroup[1] ||
                    !this.forPostId ||
                    !this.form.changeUserId ||
                    !this.form.changeStartDate ||
                    !this.form.changeEndDate ||
                    !this.indexCod ||
                    !this.formInline.time2
                ) {
                  let params = {
                    corpUsersId: this.paramsList.员工,
                    suShiftId: this.shiftAndGroup[0],
                    suGroupId: this.shiftAndGroup[1],
                    postId: this.forPostId,
                    changeUserId: this.form.changeUserId,
                    changeStartDate: this.form.changeStartDate,
                    changeEndDate: this.form.changeEndDate,
                    codes: [this.indexCod],
                    time: this.formInline.time2
                  };
                  console.log(params, "params");
                  return this.$message.warning("请完善表单");
                }*/
        let yieldList = [];
        let energyList = [];
        let sumYield = 0;
        let sumEnergy = 0;
        console.log(this.timeYieldData, 'this.timeYieldData')
        for (let i = 0; i < this.timeYieldData.length; i++) {
          if (this.timeYieldData[i].flag == 1) {
            for (let j = 0; j < i; j++) {
              sumYield += this.timeYieldData[j].yield * 100000;
              sumEnergy += this.timeYieldData[j].energy * 100000;
            }
            if (i != 0) {
              yieldList.push(sumYield / 100000);
              energyList.push(sumEnergy / 100000);
            }
            break;
          }
        }
        sumYield = 0;
        sumEnergy = 0;
        for (let i = 0; i < this.timeYieldData.length; i++) {
          if (this.timeYieldData[i].flag == 1) {
            sumYield += this.timeYieldData[i].replaceData * 100000;
            sumEnergy += this.timeYieldData[i].energy * 100000;
          }
        }
        yieldList.push(sumYield / 100000);
        energyList.push(sumEnergy / 100000);
        if (this.timeYieldData[this.timeYieldData.length - 1].flag == 0) {
          sumYield = 0;
          sumEnergy = 0;
          let flag = false;
          for (let i = 0; i < this.timeYieldData.length; i++) {
            if (i != 0 && this.timeYieldData[i].flag == 0 && this.timeYieldData[i - 1].flag == 1) {
              flag = true;
            }
            if (flag) {
              sumYield += this.timeYieldData[i].yield * 100000;
              sumEnergy += this.timeYieldData[i].energy * 100000;
            }
          }
          yieldList.push(sumYield / 100000);
          energyList.push(sumEnergy / 100000);
        }
        let params = {
          corpUsersId: this.paramsList.员工,
          suShiftId: this.shiftAndGroup[0],
          suGroupId: this.shiftAndGroup[1],
          postId: this.forPostId,
          changeUserId: this.form.changeUserId,
          changeStartDate: this.formInline.time2 + " " + this.form.changeStartDate + ":00",
          changeEndDate: this.formInline.time2 + " " + this.form.changeEndDate + ":00",
          codes: [this.indexCod],
          time: this.formInline.time2,
          yieldList: yieldList,
          energyList: energyList
        };
        // params.changeStartDate = new Date(params.changeStartDate);
        // params.changeEndDate = new Date(params.changeEndDate);
        const res = await batchForDuty(params);
        console.log(res, "ijn");
        this.dialogVisible = false;
        this.onSearch();
        this.form = {};
        this.setData.changeData2 = {};
        this.changeValue.班次 = "";
        this.changeValue.班组 = "";
        this.changeValue.员工 = "";
        this.forPost = "";
        this.setData.isCheck = true;
        this.setData.topIsMenu = true;
        this.setData.topIsCheMenu = false;
        this.checkboxYes = [];
        this.setData.allText = "全选";
      } else {
        if (
            !this.paramsList.员工 ||
            !this.paramsList.班次 ||
            !this.paramsList.班组 ||
            !this.forPostId ||
            !this.form.changeUserName ||
            !this.form.changeStartDate ||
            !this.form.changeEndDate ||
            this.checkboxYes.length == 0 ||
            !this.formInline.time
        ) {
          return this.$message.warning("请完善表单");
        }
        let params = {
          corpUsersId: this.paramsList.员工,
          suShiftId: this.paramsList.班次,
          suGroupId: this.paramsList.班组,
          postId: this.forPostId,
          changeUserName: this.form.changeUserName,
          changeStartDate: this.form.changeStartDate,
          changeEndDate: this.form.changeEndDate,
          codes: this.checkboxYes,
          time: this.formInline.time,
        };
        const res = await batchForCheck(params);
        if (res.data.code == "0000") {
          this.$message.success("替岗成功");
        } else {
          this.$message.success(res.data.msg);
        }
        this.codeList = res.data.data;
        this.innerSave = true;
        console.log(res, "resresres");
        this.setData.isCheck = true;
        this.setData.topIsMenu = true;
        this.setData.topIsCheMenu = false;
        this.checkboxYes = [];
        this.setData.allText = "全选";
      }
    },
    // 全部替岗保存前的校验
    async saveForPostRlus() {
      let params = {
        corpUsersId: this.paramsList.员工,
        suShiftId: this.paramsList.班次,
        suGroupId: this.paramsList.班组,
        postId: this.forPostId,
        changeUserName: this.form.changeUserName,
        changeStartDate: this.form.changeStartDate,
        changeEndDate: this.form.changeEndDate,
        codes: this.checkboxYes,
        time: this.formInline.time,
      };
      await batchForDuty(params);
      this.innerSave = false;
      this.dialogVisible = false;
      this.onSearch();
      this.form = {};
      this.setData.changeData2 = {};
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
    },
    closedialogVisible() {
      this.dialogVisible = false;
      this.form = {};
      this.setData.changeData2 = {};
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
    },
    async getCenter() {
      const res = await selectWorkShopName();
      this.workCenter = res.data.data;
      this.formInline.workShopId = res.data.data[0].id;
      this.processSelectByWorkShopIdList(res.data.data[0].id);
      this.formInline.processId = this.processId;

    },
    // 查询人员替岗多台机器信息
    async selectAllByUser() {
      const res = await selectAllByUserChange({
        time: this.formInline.time,
        codes: this.checkboxYes,
      });
      return res.data.data;
    },
    async onSubmit() {
      console.log(this.checkboxYes);
      if (this.checkboxYes.length > 0) {
        this.formInline.time2 = this.formInline.time;
        const res = await this.selectAllByUser();
        this.dialogVisible = true;
        this.title = "批量替岗";
        this.codesTitle = this.checkboxYes;
        this.setData.changeData2 = res;
        // console.log( this.list[this.checkboxYes[0]],'this.checkboxYes.length',this.setData.changeData2)
        this.forPostItem = res.工种;
      } else {
        this.$message.warning("请选择替岗机型");
      }
    },
    getClassAndPepoleMethod(code, postId, item) {
      getClassAndPepole(
          this.changeValue.班次,
          code,
          postId,
          this.formInline.time
      ).then((res) => {
        this.changeData.班组 = res.data.data.班组;
        this.changeData.员工 = res.data.data.员工;
        this.paramsList.班次 = item.id;
      });
    },
    getClassAndPepoleMethod2(code, postId, suGroupId, item) {
      getClassAndPepole(
          this.changeValue.班次,
          code,
          postId,
          this.formInline.time,
          suGroupId
      ).then((res) => {
        this.changeData.员工 = res.data.data.员工;
        this.paramsList.班组 = item.id;
      });
    },
    async getClassAndPepoleMethod3(value, item, indexCod, value3) {
      // getClassAndPepole(this.changeValue.班次, code, postId,this.formInline.time,suGroupId).then(res => {
      //   console.log(res,'ress')
      //   this.changeData.员工 = res.data.data.员工
      // });
      // console.log(value3,'value3')
      this.forPostItem = item;
      this.selectItem = indexCod;
      this.forPost = value.name;
      this.paramsList.员工 = value3.userId;
      this.forPostId = value.id;
      await this.$confirm("是否替岗?", "提示");
      if (!this.changeValue.班次 || !this.changeValue.班组) return;
      this.dialogVisible = true;
      this.title = "替岗";
    },
    //获取人员
    getUser(mechineIndexId, userId) {
      getpepoelChange(mechineIndexId, userId, this.formInline.time, this.formInline.workShopId).then(
          (res) => {
            this.changeValue = {};
            this.changeData = {};
            this.$nextTick(() => {
              this.setData.changeData2 = JSON.parse(
                  JSON.stringify(res.data.data)
              );
            });
            this.changeData = res.data.data;
          }
      );
    },
    boxStyle(index) {
      let style = "";
      switch (index) {
        case 0:
          style = "primary";
          break;
        case 1:
          style = "success";
          break;
        case 2:
          style = "info";
          break;
        case 3:
          style = "warning";
          break;
        case 4:
          style = "danger";
          break;
        case 5:
          style = "";
          break;
      }
      return style;
    },
    sortChange() {
      this.queryJobReplaceDetails();
    },
    async queryJobReplaceDetails() {

      let obj = {
        "workShopId": this.formInline.workShopId,
        "processId": this.formInline.processId,
        "machineId": this.machineId,
        "shiftId": this.shiftId,
        "time": this.formInline.time
      }
      console.log(this.formInline.workShopId + "," + this.formInline.processId + "," + this.formInline.time + "," + this.shiftAndGroup[0]);
      const res = await this.request.post(this.proApis.QUERYJOBREPLACEDETAILS, obj)
      this.timeYieldData1 = res.data.data
      this.originalTableData = res.data.data;
      console.log(res.data.data, '-------')
      this.originYield = res.data.data[0].yield
      console.log("" + res.data.data);
    },
    queryShiftOptions(val) {
      this.queryShift(val);
    },
    //根据工序获取班制
    async queryShift(val) {
      let res = await this.request.get(this.proApis.SELECTBYCORPID + "?processId=" + val)
      if (res.data.data.length > 0) {
        this.shiftOptions = res.data.data;
      } else {
        this.shiftNameValue = '';
        this.shiftOptions = [];
      }
    },
    queryWorkShop() {
      this.queryWorkShopOptions();
    },
    //根据车间获取班组
    async queryWorkShopOptions() {
      let res = await this.request.post(this.proApis.SELECTGROUPNAME + "?workShopId=" + this.formInline.workShopId)
      this.workShopOptions = res.data.data;
      this.queryUser();
    },
    queryPost() {
      this.queryPostOptions();
    },
    async queryPostOptions() {
      let res = await this.request.post(this.proApis.SELECTALLPOST)
      this.postOptions = res.data.data;
    },
    queryUser() {
      this.queryUserOptions();
    },
    //根据班组查询人员
    async queryUserOptions() {
      this.userNameValue = '';
      let obj = {"departmentId": this.groupNameValue}
      let res = await this.request.post(this.proApis.SELECTUSERBYDEPT, obj)
      this.userOptions = res.data.data;
    },
  },
};
</script>
<style>
.allBtn .el-checkbox-button__inner {
  padding: 12px 20px !important;
  border-radius: 3px !important;
  margin: 0 5px;
  background: #409eff;
  color: #fff;
}

.rygl .el-checkbox-button__inner {
  padding: 0 !important;
  width: 100%;
  border-radius: 8px 8px 8px 8px !important;
}

.el-checkbox-button.is-disabled .el-checkbox-button__inner {
  cursor: default;
}

.rygl .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background: #fff;
  color: #333;
  /*box-shadow: 0 0 3px 3px rgba(77,138,240,.2) !important;*/
}
</style>
<style lang="scss" scoped>
.container_title {
  font-weight: 700;
  font-size: 20px;
  padding-left: 20px;
  padding-top: 20px;
}

.inner {
  /deep/ .el-radio-button {
    margin: 7px;
  }
}

/deep/ .el-radio-button__inner {
  border-left: 0.0625rem solid #dcdfe6;
}

/deep/ .el-collapse-item__header {
  font-size: 18px;
  font-weight: 550;
}

.label {
  white-space: nowrap;
  margin-right: 10px;
  width: 120px;
  text-align: right;
}

.table {
  margin-top: 35px;

  /deep/ th {
    background: rgba(224, 226, 228, 1) !important;
    color: #000 !important;
  }
}
</style>
